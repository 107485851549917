import React, { useState } from "react";
import { graphql } from 'gatsby'
import { Link } from "@StarberryUtils";
import {Col, Row, Container } from 'react-bootstrap';
import HeaderOne from "../components/header/header"
// import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import FeaturedProperties from "../components/PropertyDetails/Similar/featured-properties"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import OfficeNumbers from "../components/Static/OfficeNumbers/area-guide-stats"
import ReactMarkdown from "react-markdown"
import Map from '../components/map/office-details-map'
import MGFav from '../components/Static/MgFavourites/MgFavourites'
import $ from 'jquery';
import SEO from "../components/seo"
import {
  isMobileOnly,
  isTablet,
  // browserName
} from "react-device-detect";

import StaticBanner from "../components/Static/StaticBanner/area-guide-banner"
import StaticVideo from "../components/Static/StaticBanner/area-guide-static-video"
import {
	MEET_THE_TEAM_PAGE_URL,
	// CONTACT_PAGE_URL
} from "../components/common/site/constants";

import { inViewOptions } from '../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const intro = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
	  y: 0,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.8
      }
    }
}
const contentContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.8
      }
    }
}
const contentItemText = {
    hidden: { opacity: 0, y: 50 },
    visible: {
		opacity: 1,
		y: 0,
		transition: {
			ease: [0.33, 1, 0.68, 1],
			delay: 0.4,
			duration: 0.8
		}
	}
}
const contentItemImageLeft = {
    hidden: { opacity: 0, x: 50 },
    visible: {
		opacity: 1,
		x: 0,
		transition: {
			ease: [0.33, 1, 0.68, 1],
			duration: 0.5
		}
	}
}
const contentItemImageRight = {
    hidden: { opacity: 0, x: -50 },
    visible: {
		opacity: 1,
		x: 0,
		transition: {
			ease: [0.33, 1, 0.68, 1],
			duration: 0.5
		}
	}
}
const MotionContainer = motion(Container)
const MotionRow = motion(Row)
const MotionCol = motion(Col)

const AreaGuideDetail = (props) => {
    const data = props.data.strapiAreaGuides
    const cat = props.data.allStrapiLocalLifeCategories.edges
    const allres = props.data.allStrapiRestaurants
    const featured = props.data.allStrapiAreaGuides.edges.length

    const SaleData = props.data.strapidata?.query1;
    const LetData = props.data.strapidata?.query2;
    const ToLetData = props.data.strapidata?.query3;
    const SoldData = props.data.strapidata?.query4;

const [active, setActive] = useState('Read More');

const handleAccordionClick = (event) => {
    var thishash = '#'+event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top -50
    }, 1000);
    
    if (active === 'Read More') {
        setActive('Read Less')
    } else {
        setActive('Read More')
    }
}

var meta_desc = "Explore all the things to love about "+data.Title+", from restaurants to days out. As experienced local estate agents, we know more than a thing or two about the area. "

	return (
        <div className="area-guide-details">
			<HeaderOne />
            <SEO location={props.location} title={`${data ? data.Meta_Title : ''} Area Guide`} description={meta_desc} />

			{data.Embed_Video_URL && (!isMobileOnly) ?
                <StaticVideo Title={data.Title} video={data.Embed_Video_URL} CTA_1_URL="test" CTA_2_URL="#" Banner_Image={data.Banner_Image} Area_local_loving_life={data.Local_life_things_to_dos} id={data.strapiId} data={data} img="area-guides.Banner_Image.details"/>
                : <StaticBanner Title={data.Title} video={data.Embed_Video_URL} CTA_1_URL="test" CTA_2_URL="#" Banner_Image={data.Banner_Image} Area_local_loving_life={data.Local_life_things_to_dos} id={data.strapiId} data={data} img="area-guides.Banner_Image.details"/>
            }

			<InView {...inViewOptions}>
				{({ ref, inView }) => (
					<MotionContainer
						className="intro"
						ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={intro}
					>
						<Row>
							<Col xs={12} sm={12} lg={9}>
								<div className="content-one">
									<ReactMarkdown source={data.Intro_Content} allowDangerousHtml />
								</div>
							</Col>
							{data.Speak_With &&
								<Col xs={12} sm={12} lg={3} className="east-schedule">
									<div className="profile-item d-flex align-items-center">
										<span className="profile-img">
											{data.Speak_With.Tile_Image ? <img loading="lazy" src={data.Speak_With.Tile_Image.publicURL} alt={`${data.Speak_With.Name} - Martyn Gerrard`}/> : '' }
										</span>
										<div className="info">
											<strong className="pro-name">{data.Speak_With.Name}</strong>
											<p className="pro-post">{data.Speak_With.Designation}</p>
											<div className="bio-contact">
												<a href={`tel:${data.Speak_With.Phone}`}>{data.Speak_With.Phone} </a><Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${data.Speak_With.URL}`}>Bio</Link>
											</div>
										</div>
									</div>
								</Col>
							}
						</Row>
					</MotionContainer>
				)}
			</InView>


            <Container className="main-content">
		        {data.Content.Add_Block.map((content, i) => {

				if (content.Content && content.Content.length < 600) {
				    var long_desc_first_part = content.Content;
				}
				else {
				    var long_desc_first_part = content.Content ? content.Content.split(/\s+/).slice(0, 60).join(" ")+'...' : '';
				}


		          return (
                      <React.Fragment key={i}>
                          {i % 2 == 0 ? 
                              <InView {...inViewOptions}>
                                  {({ ref, inView }) => (
                                      <MotionRow
                                          id={i}
                                          ref={ref}
                                          initial="hidden"
                                          animate={inView ? "visible" : "hidden"}
                                          variants={contentContainer}
                                      >
                                          <MotionCol
                                              xs={content.Image ? '12' : '12'}
                                              sm={12}
                                              lg={6}
                                              className="content area-content-guide area-content-block-1"
                                              variants={contentItemText}
                                          >
                                              <div className="area-guide-read-more">{long_desc_first_part != null && long_desc_first_part != "" &&
                                                  <>
                                                      {active === 'Read Less' ? <ReactMarkdown source={content.Content} allowDangerousHtml /> : <ReactMarkdown source={content.Content.length > 600 ? long_desc_first_part : content.Content} allowDangerousHtml /> }
                                                      {content.Content.length > 600 && <a href="javascript:void(0)" className="read-more custom-link" onClick={() => handleAccordionClick(i)}>{active}</a>}
                                                  </>
                                              }
                                              </div>
                                          </MotionCol>
                                          <MotionCol
                                              xs={12}
                                              sm={12}
                                              lg={6}
                                              variants={contentItemImageLeft}
                                          >
                                              {content.Image ? <img loading="lazy" src={content.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${content.Content ? content.Content.split('\n')[0].replace( /(<([^>]+)>)/ig, '').replace("&rsquo;",'') : ''} - Martyn Gerrard`}/> : '' }
                                          </MotionCol>
                                      </MotionRow>
                                  )}
                              </InView>
                          :
                              <InView {...inViewOptions}>
                                  {({ ref, inView }) => (
                                      <MotionRow
                                          id={i}
                                          ref={ref}
                                          initial="hidden"
                                          animate={inView ? "visible" : "hidden"}
                                          variants={contentContainer}
                                      >
                                          <MotionCol
                                              xs={12}
                                              sm={12}
                                              lg={6}
                                              variants={contentItemImageRight}
                                          >
                                              {content.Image ? <img loading="lazy" src={content.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${content.Content ? content.Content.split('\n')[0].replace( /(<([^>]+)>)/ig, '').replace("&rsquo;",'') : ''} - Martyn Gerrard`}/> : '' }
                                          </MotionCol>
                                          <MotionCol
                                              xs={content.Image ? '12' : '12'}
                                              sm={12}
                                              lg={6}
                                              className="content  area-content-guide  area-content-block-2"
                                              variants={contentItemText}
                                          >
                                              <div className="area-guide-read-more">
                                                  {long_desc_first_part != null && long_desc_first_part != "" &&
                                                      <>
                                                          {active === 'Read Less' ? <ReactMarkdown source={content.Content} allowDangerousHtml /> : <ReactMarkdown source={content.Content.length > 600 ? long_desc_first_part : content.Content} allowDangerousHtml /> }
                                                          {content.Content.length > 600 && <a href="javascript:void(0)" className="read-more custom-link" onClick={() => handleAccordionClick(i)}>{active}</a>}
                                                      </>
                                                  }
                                              </div>
                                          </MotionCol>
                                      </MotionRow>
                                  )}
                              </InView>
                          }
                      </React.Fragment>
                  );
		         })}
		         

            </Container>

			{/* {featured != 0 ?
			<MGFav cat={cat} Title={data.Title} Local_life_things_to_dos={data.Local_life_things_to_dos} allres={allres}/> : '' } */}


	         {data.Latitude ? 
	        <Map lat={data.Latitude} log={data.Longtitude} Title={data.Title}/> : '' }


	        {data.Statistics ? 
            <OfficeNumbers Statistics_Intro={data.Statistics_Intro} Statistics={data.Statistics} Market_Report_Video_URL={data.Market_Report_Video_URL} Market_Report_Image={data.Market_Report_Image} Title={data.Title}/> : '' }
            
			<FeaturedProperties place={data.Title} SaleData={SaleData} LetData={LetData} ToLetData={ToLetData} SoldData={SoldData}/>
			<CanHelp />
			<Footer area={data.URL}  Select_Popular_Search="Static_Contact" popularSearch="static" office={data.Choose_Office} page="area"/>
		</div>
    );
}

export default AreaGuideDetail

export const pageQuery = graphql`query AreaGuideQuery($slug: String!, $title: String!) {
  strapiAreaGuides(strapiId: {eq: $slug}) {
    URL
    Banner_Image {
      internal {
        description
      }
    }
    Embed_Video_URL
    Content {
      Add_Block {
        Content
        Image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 550, layout: CONSTRAINED)
          }
        }
      }
    }
    Intro_Content
    Meta_Description
    Meta_Title
    Statistics_Intro
    Title
    id
    strapiId
    Latitude
    Longtitude
    Market_Report_Video_URL
    Market_Report_Image {
      publicURL
    }
    Speak_With {
      Name
      URL
      Designation
      Phone
      Tile_Image {
        publicURL
      }
    }
    Choose_Office {
      Name
      URL
    }
    Statistics {
      Add_Stats {
        Content
        Count
      }
    }
    Local_life_things_to_dos {
      URL
      Name
      Tile_Image_Label
      Tile_Image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
        }
      }
      Short_Description
      Address
      Category
      Featured
    }
    Area_Local_Loving_Life {
      Clubs_and_Societies_Intro
      Events_Intro
      Restaurants_and_Bars_Intro
      Shops_Intro
    }
    imagetransforms {
      Banner_Image_Transforms
    }
  }
  allStrapiAreaGuides(
    filter: {strapiId: {eq: $slug}, Local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}}
  ) {
    edges {
      node {
        id
        strapiId
      }
    }
  }
  allStrapiLocalLifeCategories {
    edges {
      node {
        Name
        URL
        strapiId
      }
    }
  }
  allStrapiRestaurants(
    filter: {area_guide: {id: {eq: $slug}}, Featured: {eq: true}, Publish: {eq: true}}
  ) {
    edges {
      node {
        Address
        Featured
        Name
        URL
        Tile_Image_Label
        Short_Description
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
        strapiId
        Category {
          Name
          URL
        }
      }
    }
  }
  strapidata {
    query1: properties(
      where: {department: "residential", publish: true, status: "For Sale", display_address_contains: $title}
      sort: "createdAt:DESC"
      limit: 10
    ) {
        search_type
        id
        crm_id
        price
        price_qualifier
        slug
        display_address
        _id
        images
        imagetransforms
        floorplan
        title
    }
    query2: properties(
      where: {department: "residential", publish: true, status: "Let", display_address_contains: $title}
      sort: "createdAt:DESC"
      limit: 10
    ) {
        search_type
        id
        crm_id
        price
        price_qualifier
        slug
        display_address
        _id
        images
        imagetransforms
        floorplan
        title
    }
    query3: properties(
      where: {department: "residential", publish: true, status: "To Let", display_address_contains: $title}
      sort: "createdAt:DESC"
      limit: 10
    ) {
        search_type
        id
        crm_id
        price
        price_qualifier
        slug
        display_address
        _id
        images
        imagetransforms
        floorplan
        title
    }
    query4: properties(
      where: {department: "residential", publish: true, status: "Sold", display_address_contains: $title}
      sort: "createdAt:DESC"
      limit: 10
    ) {
        search_type
        id
        crm_id
        price
        price_qualifier
        slug
        display_address
        _id
        images
        imagetransforms
        floorplan
        title
    }
  }
}
`

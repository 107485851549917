import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';

const NumberCounter = (props) => {
	
	return (<React.Fragment>
				<React.Fragment>
					<strong>
						<CountUp
							end={props.start}
							separator=","
							start={0}
							duration={props.duration}
							suffix={props.sym}
							decimals={props.decimal}
						/></strong>

				</React.Fragment>

	</React.Fragment>)

}

export default NumberCounter;
// import { Link } from "@StarberryUtils";
// import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "./OfficeNumbers.scss";
import profImg3 from "../../../images/profile-img03.png"
import Counter from '../Counter/number-counter';
import ReactMarkdown from "react-markdown"
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 60,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}



const AreaGuideOfficeNumbers=(props)=>{
    const [isOpen, setOpen] = useState(false);

     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }
  return(
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="office-numbers data area-guide-stats"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col md={12}>
              {/* <ScrollAnimation animateIn='fadeInUp' animateOnce> */}
                  <div className="detail d-md-flex justify-content-between">
                  <ReactMarkdown source={props.Statistics_Intro ? props.Statistics_Intro : ''} allowDangerousHtml className="desc" />
                  {props.Market_Report_Video_URL ?
                      <div className="profile-item d-flex align-items-center">
                          <span className="profile-img">
                              <img loading="lazy" src={props.Market_Report_Image ? props.Market_Report_Image.publicURL : profImg3} alt="Image"/>
                              <span className="play-icn ag-play"  tabIndex="0"  onClick={()=> setOpen(true)} onKeyDown={()=> setOpen(true)}><i className="icon-Play-white"></i></span>
                          </span>
                          <div className="info">
                              <p className="pro-post"><span className="ag-play-1" tabIndex="0" onClick={() => { setOpen(true); trackerVideo(props.Title);}} onKeyDown={()=> setOpen(true)}>Watch</span> the managers sales market report.</p>
                          </div>

                          {isOpen &&
                              <ModalWindow>
                                  <ModalVideo channel='custom' isOpen={isOpen} url={props.Market_Report_Video_URL} onClose={() => setOpen(false)} />
                              </ModalWindow>
                          }

                  </div>
                  : '' }
                  </div>
                  {/* </ScrollAnimation> */}
              </Col>
            </Row>

              {/* <ScrollAnimation animateIn='fadeInUp' animateOnce > */}
            <Row>
              {props.Statistics.Add_Stats.map((item, i) => {
              let sym = item.Count[item.Count.length -1]
              let symbol = ''
              if(isNaN(sym))
              {
                symbol = sym
              }
              else
              {
                symbol = ''
              }
              let decimal = 0
              if(item.Count.indexOf('.') !== -1) {
                decimal = 1
              }
              let start = ''
              if(item.Count.indexOf('£') !== -1) {
                start = "yes"
              }
                return (
                  <Col md={6} xl={3} className="area-guide-number" key={i}>
                      <strong className="numbers">
                      {start == "yes" ? '£' : ''}
                      <Counter
                            start={item.Count.replace("%",'').replace(",",'').replace("+",'').replace("£",'').replace("m",'')}
                            sym={symbol}
                            duration={5}
                            />
                            </strong>
                      <ReactMarkdown source={item.Content ? item.Content : ''} allowDangerousHtml />
                  </Col>
                )
              })}
            </Row>
            {/* </ScrollAnimation>  */}
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default AreaGuideOfficeNumbers

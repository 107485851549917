import { Link } from "@StarberryUtils";
import React, { useState, useRef  } from "react"
import { Row, Col, Container, Dropdown, Modal } from "react-bootstrap"
import "../../MgServices/Breadcrumb/Breadcrumb.scss"
import "./StaticBanner.scss"
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"
import ShareImg from "../../../images/share-white.png";
import getVideoId from 'get-video-id';
import ReactPlayer from 'react-player'
import $ from "jquery"

import loadable from '@loadable/component'

import { useLocation } from "@reach/router"
import SocialShare from "../../utils/socialShare";

import {
    isIOS,
    isMobile
} from "react-device-detect";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const YoutubeBackground = loadable(() => import('react-youtube-background'))
const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2 
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 60 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const StaticBannerAreaGuideGGFX = (props) => {

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  const location = useLocation();
    
  const shareUrl = location.href

  var youtube_id = ''
  if(props.video) {
    youtube_id = getVideoId(props.video);
  }


  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

  const image_url = props.Banner_Image.internal.description ? props.Banner_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 

  let processedImages = JSON.stringify({});
  if (props.data?.imagetransforms?.Banner_Image_Transforms) {
      processedImages = props.data.imagetransforms.Banner_Image_Transforms;
  }

  const title = props.Title ? props.Title.replace( /(<([^>]+)>)/ig, '') : ''
  const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);


 const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

}
const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
}
const showControls = () => {
    setVideoControls(true)
}



  return (
    <InView threshold={0}>
      {({ ref, inView }) => (
        <motion.div
          className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
            <div className="banner-item">

             
            <YoutubeBackground
              videoId={youtube_id.id}
              className="area-guide-banner-video"
            
            >
              <div className="banner-content mgservice-head">
                <Container>
                  <Row>
                    <Col xs={12} className="exp-btns">
                      <motion.div variants={titleVariants}>
                      {props.tag != "local-life" ? 
                      <Breadcrumbs url="area-guides" tag="contact" Title={props.Title}/> : <Breadcrumbs url={props.main_url == "main" ? "about" : "area-guides" } tag="local-main-landing" category={props.category} Title={props.Title.replace(/(<([^>]+)>)/gi, "").replace("&amp;","&").replace(" &amp;"," &").replace("&nbsp;","  ")}/> }
                        {props.tag != "local-life" ? <h1>{props.Title} Area Guide</h1> : '' }
                        {props.tag == "local-life" ? <ReactMarkdown source={props.Title} allowDangerousHtml /> : '' }
                        <div className="btns-wrp d-md-flex align-items-center">
                          {props.Area_local_loving_life ? 
                            <Link to="local-loving-life" className="btn cta-1">Loving Local Life</Link> : ''
                          }

                          {props.all_cat ?
                            <>
                              {props.all_cat.map((data, i) => {
                                return (
                                  <Link to={`../../../${data.URL}/${props.page_url}`} className="btn">{data.Name}</Link>
                                )
                              })}
                            </> : ''
                          }

                          {props.area_drop_down ?
                          <div className="area_drop_down">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              All Areas
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {props.area_drop_down.map((data, i) => {
                                return (
                                  <Dropdown.Item><Link to={`${data.URL}`}>{data.Title}</Link></Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                              
                          </div> : ''
                          }
                        </div>
                        {props.tag != "local-life" ? 
                        <div className="share" onClick={openShareicons}>
                          <p><img loading="lazy" src={ShareImg} alt="" /><span>Share with a friend</span></p>
                          {Shareicons &&
                            <SocialShare openShareicons={openShareicons} />
                          }
                        </div> : '' }
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="area-guide-video-play-button">
                    <span  onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                        <i className="icon-play-transparent"></i>
                    </span>
              </div>
            </YoutubeBackground>
            {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>

                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerShare(props.title)} url={props.video} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' light={true}/>
                 
                    </Modal.Body>
                </Modal>

            }
              
            </div>
        </motion.div>
      )}
    </InView>
  )
}

export default StaticBannerAreaGuideGGFX
